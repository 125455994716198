@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure your CSS for scroll pane is defined here */
.scroll-pane {
  overflow-y: auto; /* Add vertical scroll */
  max-height: 80vh; /* Limit height for scrolling */
  padding-right: 10px; /* Space for scrollbar */
}

/* Container width for better readability */
.container {
  max-width: 1200px; /* Limit width */
  margin: 0 auto; /* Center container */
}

/* Custom styles for buttons */
button {
  font-weight: 600; /* Make button text bold */
  padding: 10px 20px; /* Adjust padding */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Optional: Additional button styles */
button:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Subtle background change on hover */
}

/* Style the code snippets */
.code-container {
  background-color: #f5f5f5; /* Light background for code snippets */
  border: 1px solid #ddd; /* Border to define edges */
  border-radius: 5px; /* Rounded corners */
  padding: 15px; /* Space inside the container */
  overflow-x: auto; /* Horizontal scroll if needed */
}

/* Typing effect for text */
.typical {
  border-right: 2px solid white; /* Cursor effect */
  white-space: nowrap;
  overflow: hidden;
  animation: blinkCursor 1s infinite step-start;
}

@keyframes blinkCursor {
  50% {
    border-color: transparent;
  }
}

/* Add these styles to your CSS file */
.link-primary {
  color: #113b7d;
  text-decoration: none;
  font-weight: bold;
}

.link-primary:hover {
  color: #d0e0f0;
}

